import { useState } from "react"
import styled from "styled-components"

import {
  Badge,
  Checkbox,
  CircularProgress,
  InputAdornment,
  TextField,
} from "@material-ui/core"
import { Search } from "@material-ui/icons"
import { useDebouncedCallback } from "use-debounce"

import { useTranslate } from "src/i18n/useTranslate"
import { DropdownButton } from "src/ui/Button/DropdownButton"
import { TextButton } from "src/ui/Button/TextButton"
import { TSelectOption } from "src/ui/DropdownSelect/DropdownSelect"
import {
  BoxBottom,
  DropDownButtonLabel,
  EmptyBox,
  ErrorBox,
  Item,
  ItemsList,
  Option,
  StyledSearch,
  StyledSelect,
} from "src/ui/DropdownSelect/sharedStyles"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function DropdownMultiSelect<
  V extends string,
  O extends { [key: string]: unknown } = Record<string, unknown>,
>({
  label,
  options = [],
  selectedValues,
  onChange,
  onSearch,
  onClearSelection,
  debounceDelay = 400,
  placeholder,
  loading,
  errorMsg,
}: {
  label: string
  options: TSelectOption<V, O>[]
  selectedValues: TSelectOption<V>["value"][]
  onChange: ({
    checked,
    option,
  }: {
    checked: boolean
    option: TSelectOption<V, O>
  }) => void
  onSearch?: (s: string) => void
  onClearSelection?: () => void
  debounceDelay?: number
  placeholder?: string
  loading?: boolean
  errorMsg?: React.ReactNode
}) {
  const { t, langKeys } = useTranslate()
  const [textfieldValue, setTextfieldValue] = useState("")

  const debouncedSearch = useDebouncedCallback(
    (search: string) => onSearch?.(search),
    debounceDelay,
    {
      leading: true,
    }
  )

  function handleChangeTextField(s: string) {
    debouncedSearch(s)
    setTextfieldValue(s)
  }

  function handleOptionClick(option: TSelectOption<V, O>) {
    const isCurrentlyChecked = selectedValues.includes(option.value)

    onChange({ checked: !isCurrentlyChecked, option })
  }

  return (
    <Badge
      badgeContent={selectedValues.length}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <DropdownButton
        variant="secondary"
        displayValue={
          <DropDownButtonLabel values={selectedValues.length}>
            {label}
          </DropDownButtonLabel>
        }
        placement="bottom-start"
      >
        {() => (
          <StyledSelect>
            {onSearch && (
              <StyledSearch>
                <TextField
                  value={textfieldValue}
                  onChange={(e) => handleChangeTextField(e.target.value)}
                  fullWidth
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        {loading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : (
                          <Search color="disabled" />
                        )}
                      </InputAdornment>
                    ),
                    placeholder,
                  }}
                />
              </StyledSearch>
            )}

            {options.length === 0 && textfieldValue.length > 0 && (
              <EmptyBox>
                <MText variant="bodyS" color="secondary">
                  {t(langKeys.search_no_matches_found)}
                </MText>
              </EmptyBox>
            )}

            <ItemsList
              $showBorderTop={!!onSearch}
              $showBorderBottom={!!onClearSelection}
            >
              {options.map((option) => (
                <Option
                  key={option.label}
                  onClick={() => !option.disabled && handleOptionClick(option)}
                  tabIndex={-1}
                >
                  <Item>
                    <StyledCheckbox
                      checked={selectedValues.indexOf(option.value) > -1}
                      name={option.value}
                      disabled={option.disabled}
                    />
                    <MText
                      variant="bodyS"
                      color={option.disabled ? "secondary" : "unset"}
                    >
                      {option.label}
                    </MText>
                  </Item>
                </Option>
              ))}
            </ItemsList>

            {!!errorMsg && (
              <ErrorBox>
                <MBanner type="error" size="small">
                  {errorMsg}
                </MBanner>
              </ErrorBox>
            )}

            {onClearSelection && (
              <BoxBottom>
                <TextButton
                  onClick={onClearSelection}
                  disabled={!onClearSelection || !selectedValues.length}
                >
                  {t(langKeys.clear_selection)}
                </TextButton>
              </BoxBottom>
            )}
          </StyledSelect>
        )}
      </DropdownButton>
    </Badge>
  )
}

const StyledCheckbox = styled(Checkbox)`
  padding: ${spacing.XS} ${spacing.M} ${spacing.XS} ${spacing.XS};
`
